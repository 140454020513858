<template>
    <div class="en_add">
        <el-card>
            <el-form ref="form" :model="form" :rules="rule" label-width="100px" :inline="false" size="normal">
                <el-form-item label="优惠券名称" prop="name">
                    <el-col :span="8">
                        <el-input v-model="form.name" show-word-limit />
                    </el-col>
                </el-form-item>
                <el-form-item label="有效期" prop="bonus">
                    <el-date-picker
                        v-model="form.time"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="优惠券数量" prop="number">
                    <el-col :span="8">
                        <el-input-number v-model="form.number" :step="1"></el-input-number>
                    </el-col>
                </el-form-item>
                <el-form-item label="优惠方式" prop="discountType">
                    <el-radio-group v-model="form.discountType" :disabled="form.type == 2">
                        <el-radio :label="0">折扣减免</el-radio>
                        <el-radio :label="1">定额减免</el-radio>
                    </el-radio-group>
                    <div style="height:10px"></div>
                    原价减
                    <el-select style="width:100px" v-if="form.type == 2" size="small" v-model="form.discountValue">
                        <el-option :value="20" label="20"> </el-option>
                        <el-option :value="10" label="10"> </el-option>
                    </el-select>
                    <el-input v-else size="small" v-model="form.discountValue" style="width:100px"></el-input>
                    {{ form.discountType == 0 ? "%" : "元" }}
                </el-form-item>
                <el-form-item label="使用限制">
                    <el-input size="small" style="width:100px" v-model="form.limit"></el-input>
                    每人可以领取多少张
                    <br />
                    <div class="warning" style="color:#fda400;line-height:30px">
                        若不限次数，请填0
                    </div>
                </el-form-item>
                <el-form-item label="类型">
                    <el-radio-group v-model="form.type" @change="typeChange">
                        <!-- <el-radio :label="0">通用</el-radio> -->
                        <el-radio :label="1">活动</el-radio>
                        <el-radio :label="2">VIP会员</el-radio>
                    </el-radio-group>
                </el-form-item>
                <template v-if="form.type == 1">
                    <el-form-item label="选择活动">
                        <el-col :span="8">
                            <fuzzy-selection
                                ref="fuzzySelection"
                                :value.sync="form.activityId"
                                :type="4"
                                clearable
                                tipName="活动"
                            />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="适用票种" prop="ticketIds">
                        <el-checkbox-group v-model="form.ticketIds">
                            <!-- <el-checkbox label="-1">全部</el-checkbox> -->
                            <el-checkbox :label="item.id + ''" v-for="(item, index) in tickList" :key="index">{{
                                item.name
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="票种类型" prop="ticketType">
                        <el-radio-group v-model="form.ticketType">
                            <el-radio :label="0">单人票</el-radio>
                            <el-radio :label="1">团体票</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="小程序" prop="miniprogramType">
                        <el-radio-group v-model="form.miniprogramType">
                            <!-- <el-checkbox label="-1">全部</el-checkbox> -->
                            <el-radio
                                :label="item.id"
                                v-for="(item, index) in [
                                    { id: 0, name: '扬帆小程序' },

                                ]"
                                :key="index"
                                >
<!--                              { id: 1, name: 'PAGC小程序' },-->
                              {{ item.name }}</el-radio
                            >
                        </el-radio-group>
                    </el-form-item>
                </template>
                <el-form-item label="描述">
                    <el-col :span="8">
                        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="form.desc"> </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="onSubmit">{{ id ? "修改" : "提交" }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import imgup from "@/components/upImg/index.vue";
import rules from "@/utils/rules";
import fuzzySelection from "@/components/fuzzySelection";
export default {
    components: {
        imgup,
        fuzzySelection,
    },
    data() {
        return {
            id: null,
            form: {
                name: "",
                discountType: "",
                ticketIds: [],
                time: [],
                beginTime: "",
                endTime: "",
                number: "",
                limit: "0",
                discountValue: "",
                desc: "",
                activityId: "",
                bonus: "",
                type: 1,
                miniprogramType: 0,
                ticketType: 0,
            },
            rule: rules.reqAll({
                name: "请输入优惠券名称",
                ticketIds: "请选择票种",
                logo: "请输入企业logo",
                city: "请输入省份和城市",
                people: "请输入公司人事",
                financing: "请输入融资情况",
                type: "请输入类型",
                business: "请输入行业",
                serverArea: "请输入业务区域",
                companyDesc: "请输入企业介绍",
                intro: "请输入企业简介",
            }),
            action: {
                action: "bonus",
            },
            province: {},
            city: {},
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            cardList: [],
            tickList: [],
            first: true,
        };
    },
    watch: {
        "form.activityId"(newValue, oldValue) {
            this.findTick();
            if (this.first) {
                return;
            }
            this.form.ticketIds = [];
        },
        // "form.type"(val) {
        //     if (val == 2) {
        //         this.form.discountValue = 20;
        //         this.form.discountType = 0;
        //     }
        // },
    },
    computed: {},
    created() {
        sessionStorage.setItem("action", "bonus");
        this.getform();
        // this.getEnv()
    },
    methods: {
        change(val) {
            this.form.desc = val;
        },
        typeChange() {
            if (this.form.type == 2) {
                this.form.discountValue = 20;
                this.form.discountType = 0;
            }
        },
        async findTick() {
            var { data: res } = await this.$http.get(
                `/admin/Activity/getTicketList?activityId=${this.form.activityId}&page=1&pageSize=5000`
            );

            //   console.log(res)
            this.tickList = res.data.list;
            this.first = false;
        },
        async onSubmit() {
            var url;
            if (this.id) {
                url = "/admin/Coupon/edit";
            } else {
                url = "/admin/Coupon/add";
            }

            var { data: res } = await this.$http.post(url, {
                ...this.form,
                id: this.id,
                ticketIds: this.form.ticketIds.toString(),
                beginTime: this.form.time[0],
                endTime: this.form.time[1],
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.push("/coupon_list");
            }
        },
        async getform() {
            if (!this.$route.query.id) {
                return;
            }
            this.id = this.$route.query.id;
            const { data: res } = await this.$http.get("/admin/Coupon/getById?id=" + this.id);
            if (res.errorCode == 200) {
                // console.log(res);
                // console.log(res.data);
                this.form = {
                    ...res.data,
                    time: [res.data.beginTime, res.data.endTime],
                    ticketIds: res.data.ticketIds.split(","),
                };

                this.$nextTick(() => {
                    if (!this.$refs.fuzzySelection) {
                        return;
                    }
                    this.$refs.fuzzySelection.options = [{ id: this.form.activityId, title: this.form.activityName }];
                    this.$refs.fuzzySelection.val = this.form.activityId;
                });
            }
            //  this.form.initViews =
        },
        // async getEnv() {
        //   var { data } = await this.$http.get('/admin/UserCard/getList')
        //   //   console.log(data);
        //   this.cardList = data.data.list
        // },
    },
};
</script>

<style lang="less">
.en_add {
    .box {
        display: inline-block;
        width: 300px;
        input {
            border-color: #dcdfe6 !important;
        }
    }
}
</style>
